@import url("https://fonts.googleapis.com/css?family=Nunito&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Vollkorn+SC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Stardos+Stencil&display=swap");

:root {
  --color-primary-hover-lighter: #5083ca;
  --color-primary-lighter: #3a74c3;
  --color-primary-light: #2464bd;
  --color-primary: #0e55b6;
  --color-primary-dark: #0e4fa7;
  --color-primary-darker: #0f4998;
  --color-primary-hover-darker: #0f4389;
  --color-secundary-light: none;
  --color-secundary: #f52216;
  --color-secundary-dark: none;
  --color-white: #fff;
  --color-black: #121212;
  --color-text-base: #d4c2ff;

  font-size: 62.5%;
}

* {
  margin: 0px;
  padding: 0px;
  outline: 0px;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body,
button,
input,
textarea {
  color: #fff;
  font: 500 1.6rem Nunito;
}
